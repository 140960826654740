<template>
  <div class="image-compressor">
    <img v-if="!files.length" src="@/assets/logo.png" alt="Speedy Img">
    <h1>Fast Image Compression and Conversion.</h1>
    <p>Compress and convert <strong>PNG</strong>, <strong>JPG</strong>, and <strong>WEBP</strong> to speed up your website.</p>
    <div 
      class="drop-area"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
      @click="triggerFileInput"
      :class="{ 'drag-over': isDragging }"
    >
      <p v-if="!files.length && hasPremium">Drag & Drop images here or click to select.</p>
      <p v-if="!files.length && !hasPremium && isLoggedIn">Drag & Drop <strong>5</strong> images here or click to select.</p>
      <p v-if="!files.length && !hasPremium && !isLoggedIn">Drag & Drop <strong>3</strong> images here or click to select.</p>

      <p v-if="files.length">{{ files.length }} image(s) selected</p>
      <input 
        type="file" 
        @change="handleFileSelect" 
        multiple 
        accept="image/*" 
        ref="fileInput"
        class="file-input-hidden"
      >
    </div>

    <p v-if="!files.length && !hasPremium && isLoggedIn">Get Premium to unlock unlimited image uploads.</p>
    <p v-if="!files.length && !hasPremium && !isLoggedIn">Log In to increase your image upload limit.</p>

    <!-- Image Preview & Compression Results -->
    <div v-if="files.length" class="image-list">
      <h3>Your Images</h3>
      <p v-if="!hasPremium"><strong>Sign Up</strong> and get <strong>Premium</strong> to unlock unlimited image uploads, conversion, and download all.</p>

        <!-- Download All Buttons -->
        <div class="download-all-buttons">
            <button v-if="compressedFiles.length" v-bind:class="{ 'is-disabled' : !hasPremium }" @click="downloadAll('jpeg')" class="download-all">
                <i class="icon icon-download"></i> All as JPEG
            </button>
            <button v-if="compressedFiles.length" v-bind:class="{ 'is-disabled' : !hasPremium }" @click="downloadAll('png')" class="download-all">
                <i class="icon icon-download"></i> Download All as PNG
            </button>
            <button v-if="compressedFiles.length" v-bind:class="{ 'is-disabled' : !hasPremium }" @click="downloadAll('webp')" class="download-all">
                <i class="icon icon-download"></i> Download All as WEBP
            </button>
        </div>

      <ul> 
        <li v-for="(file, index) in files" :key="index" class="image-item">
          <img :src="imagePreviews[index]" class="image-preview" alt="Preview">

          <!-- Image Info -->
          <div class="image-info">
            <strong :title="file.name">{{ file.name }}</strong>
            <p>Original: {{ formatSize(file.size) }}</p>
            <p>
              Compressed: {{ formatSize(compressedFiles[index]?.size || file.size) }} 
              ({{ getCompressionPercent(file.size, compressedFiles[index]?.size || file.size) }}% smaller)
            </p>
          </div>

          <!-- Download Buttons -->
          <div class="download-buttons">
            <button 
              @click="downloadFile(compressedFiles[index] || file, file, 'jpeg')"
              :class="{ highlight: getFileFormat(file) === 'jpeg', 'is-disabled' : !hasPremium }"
            >
                <i class="icon icon-download"></i> JPEG
            </button>
            <button 
              @click="downloadFile(compressedFiles[index] || file, file, 'png')"
              :class="{ highlight: getFileFormat(file) === 'png', 'is-disabled' : !hasPremium }"
            >
                <i class="icon icon-download"></i> PNG
            </button>
            <button 
              @click="downloadFile(compressedFiles[index] || file, file, 'webp')"
              :class="{ highlight: getFileFormat(file) === 'webp', 'is-disabled' : !hasPremium }"
            >
                <i class="icon icon-download"></i> WEBP 
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer">
    <a href="/privacy-policy" target="_blank">Privacy Policy</a>
    <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
  </div>
</template>

<script>
import JSZip from 'jszip';

export default {
  name: 'ImageCompressor',
  data() {
    return {
        services: window.services,
        isLoggedIn: window.utils.cookies.has('token'),
        hasPremium: window.utils.cookies.has('3hiagdlh5disdsag43234gh') || window.utils.cookies.has('hidl4ah4jaad3gadsdfa5dih'),
        files: [],
        compressedFiles: [],
        imagePreviews: [],
        maxWidth: 1920,
        quality: 0.8,
        isDragging: false,
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileSelect(event) {
        let selectedFiles = Array.from(event.target.files).filter(file => file.type.startsWith("image/"));

        // Limit number of files
        if (!this.isLoggedIn) {
            selectedFiles = selectedFiles.slice(0, 3);
        }
        else if (!this.hasPremium) {
            selectedFiles = selectedFiles.slice(0, 5);
        }

        this.files = selectedFiles;
        this.imagePreviews = this.files.map(file => URL.createObjectURL(file));
        this.compressedFiles = [];

        this.compressImages();
    },

    handleDrop(event) {
        this.isDragging = false;
        let droppedFiles = Array.from(event.dataTransfer.files).filter(file => file.type.startsWith("image/"));

        this.saveCount(droppedFiles.length);

        // Limit number of files
        if (!this.isLoggedIn) {
            droppedFiles = droppedFiles.slice(0, 3);
        }
        else if (!this.hasPremium) {
            droppedFiles = droppedFiles.slice(0, 5);
        }

        if (droppedFiles.length) {
            this.files = droppedFiles;
            this.imagePreviews = this.files.map(file => URL.createObjectURL(file));
            this.compressedFiles = [];
            this.compressImages();
        }
    },

    dragOver() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    async compressImages() {
      this.compressedFiles = [];
      for (const file of this.files) {
        const compressed = await this.compressImage(file);
        
        // If compression results in a larger file, use the original
        if (compressed.size >= file.size) {
          this.compressedFiles.push(file);
        } else {
          this.compressedFiles.push(compressed);
        }
      }
    },

    compressImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            // if (width > this.maxWidth) {
            //   height = Math.round((height * this.maxWidth) / width);
            //   width = this.maxWidth;
            // }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
              const compressedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: new Date().getTime()
              });
              resolve(compressedFile);
            }, 'image/jpeg', this.quality);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },

    async downloadAll(format) {

      if (!this.hasPremium) {
        return;
      }

      const zip = new JSZip();
      
      for (let i = 0; i < this.files.length; i++) {
        const file = this.compressedFiles[i] || this.files[i];
        const fileName = this.files[i].name.replace(/\.[^/.]+$/, `.${format}`); 

        zip.file(fileName, file);
      }

      const content = await zip.generateAsync({ type: 'blob' });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(content);
      a.download = `compressed_images_${format}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    },

    downloadFile(file, originalFile, format) {
      const extension = format;
      const fileName = originalFile.name.replace(/\.[^/.]+$/, `.${extension}`);
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    formatSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },

    getCompressionPercent(originalSize, compressedSize) {
      if (!originalSize || !compressedSize || compressedSize >= originalSize) return 0;
      return Math.round(((originalSize - compressedSize) / originalSize) * 100);
    },

    getFileFormat(file) {
        const extension = file.name.split('.').pop().toLowerCase();
        return extension === 'jpg' ? 'jpeg' : extension; // Normalize JPG to JPEG
    },

    saveCount(count) {
      const c = this;

      c.services.saveAnalytics({
        count: count,
      })
      .then(function() {});
    }
  }
}
</script>

<style scoped>
/* @import url('@/assets/css/icons.css'); */

.image-compressor {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
}

.image-compressor > img {
    max-width: 100%;
}

.drop-area {
  width: 100%;
  height: 200px;
  border: 2px dashed #42b983;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -moz-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  margin-top: 40px;
}

.drop-area.drag-over {
  background-color: #e3fcec;
  border-color: #3ca84b;
}

.file-input-hidden {
  display: none;
}

button {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -moz-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
}

button i {
    position: relative;
    top: 1px;
    right: 3px;
}

.image-list {
    margin-top: 40px;
}

.image-list ul {
  list-style-type: none;
  padding: 0;
}

.image-item {
  
  align-items: center;
  background: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  gap: 10px;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -moz-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);

  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.image-preview {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);
  -moz-box-shadow: 0 0 6px 0 rgba(0,0,0,0.35);

  @media screen and (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
}

.image-info {
  flex-grow: 1; /* Take the remaining space */
  text-align: center;
}

.image-info strong {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    width: 100%;
    display: inline-block;
}

.download-buttons {
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media screen and (min-width: 768px) {
    width: 100px;
    display: flex;
  }
}

.download-buttons button {
  width: 100%;
  padding: 8px;
  background-color: #3ca84b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}

.download-buttons .highlight {
  background-color: #ff9800;
}

.download-all-buttons {
  gap: 10px;
  margin-top: 15px;

  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.download-all {
  background-color: #37afd3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  display: block;

  @media screen and (min-width: 768px) {
    display: initial;
    width: intitial;
    margin-bottom: 0;
    flex: 1 1 33.33%;
  }
}

.is-disabled {
    opacity: .35;
    pointer-events: none;
}

.highlight.is-disabled {
    opacity: 1;
    pointer-events: all;
}

.footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.footer a {
  margin: 0 10px;
  text-decoration: none;
  color: #3ca84b;
}

</style>
